import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Fyndorich Fields
			</title>
			<meta name={"description"} content={"Her maçın ev sahibi gibi hissedildiği yer"} />
			<meta property={"og:title"} content={"Home | Fyndorich Fields"} />
			<meta property={"og:description"} content={"Her maçın ev sahibi gibi hissedildiği yer"} />
			<meta property={"og:image"} content={"https://fyndorich.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fyndorich.com/img/5043560.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fyndorich.com/img/5043560.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fyndorich.com/img/5043560.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fyndorich.com/img/5043560.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fyndorich.com/img/5043560.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fyndorich.com/img/5043560.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="180px 0 184px 0" background="linear-gradient(180deg,rgba(11, 15, 59, 0.81) 0%,rgba(11, 15, 59, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://fyndorich.com/img/1.jpg) 0% 70% /cover repeat scroll padding-box" min-height="50vh" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 30px 0px"
				text-align="center"
				color="--light"
				font="normal 400 18px/1.5 --fontFamily-sans"
				letter-spacing="1px"
			>
				Fyndorich Sahaları - Sadece gol değil, anılar da atın
			</Text>
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="normal 700 64px/1.2 --fontFamily-sans"
				letter-spacing="1px"
				color="#0d7d0d"
				padding="0px 250px 0px 250px"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
			>
				Fyndorich Fields
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 260px 0px 260px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Fyndorich Sahalarına hoş geldiniz - dostluk maçı, takım antrenmanı veya sadece arkadaşlarınızla tekme atmak için gideceğiniz saha. Şehrinizin kalbinde, futbol tutkusunun topluluk ruhuyla buluştuğu bir alan sunuyoruz.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0">
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">

				<Text
					margin="25px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="center"
					color="--darkL2"
					lg-width="100%"
				>
					Tek, mükemmel bakımlı, profesyonel düzeyde futbol sahamız, mükemmel bir oyun için ihtiyacınız olan her şeyle donatılmıştır. Akşam oyunları için ışıklandırılmış ve yemyeşil, bakımlı çimlerle donatılmış olan Fyndorich Fields, birinci sınıf bir oyun deneyimi sunuyor. Burada mesele sadece bir saha kiralamak değil, oyun sevgisini kucaklamaktır.

				</Text>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Text
				margin="0px 0px 50px 0px"
				font="--headline2"
				color="--dark"
				sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
			>
				Neden Fyndorich'i Seçmelisiniz?
			</Text>
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://fyndorich.com/img/2.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					Birinci Sınıf Konum: Geniş park yeri ile kolayca erişilebilir.
					<br />
					<br />
					Kaliteli Yüzey: Pürüzsüz bir oyun için yüksek kaliteli çim.
					<br />
					<br />
					Gece Oyunu: Geç saatlerdeki oyunlar için projektörlerle donatılmıştır.
					<br />
					<br />
					Özel Rezervasyon: Grubunuz için tüm alanı rezerve edin.
					<br />
					<br />
					Uygun Fiyatlar: Gizli ücretler olmadan rekabetçi fiyatlandırma.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});